import React from 'react';
import Image from 'gatsby-image/withIEPolyfill';
import { graphql, Link } from 'gatsby';
import { LayoutApp } from '../layouts/LayoutApp';
import { SEO } from '../components/SEO';

const PostItem = ({ post }) => {
  const linkTitle = `Read post "${post.frontmatter.title}"`;

  return (
    <article>
      <div className="overflow-hidden transition-shadow duration-300 bg-white border border-gray-400 rounded shadow-sm">
        <Link
          to={`/posts${post.fields.slug}`}
          aria-label={linkTitle}
          title={linkTitle}
        >
          <Image
            fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
            draggable={false}
            alt={post.frontmatter.title}
            className="w-full h-64"
          />
        </Link>
        <div className="p-5 border-t border-gray-400">
          <small className="text-gray-700">
            {post.frontmatter.date} — {post.fields.readingTime.text}
          </small>
          <div>
            <Link
              to={`/posts${post.fields.slug}`}
              aria-label={linkTitle}
              title={linkTitle}
              className="inline-block transition-colors duration-200 hover:text-indigo-700"
            >
              <header className="text-lg font-semibold tracking-wide">
                {post.frontmatter.title}
              </header>
            </Link>
          </div>
          <p className="mb-3 text-gray-800">{post.frontmatter.description}</p>
          <Link
            to={`/posts${post.fields.slug}`}
            aria-label={linkTitle}
            title={linkTitle}
            className="font-medium text-indigo-600 transition-colors duration-200 hover:text-indigo-700 hover:underline"
          >
            Read Post
          </Link>
        </div>
      </div>
    </article>
  );
};

const BlogPage = ({ data }) => {
  const posts = data.allMdx.edges;

  return (
    <LayoutApp>
      <SEO title="Blog" />
      <div className="flex flex-col mb-8 sm:flex-row sm:items-center">
        <div className="flex items-center sm:mr-6">
          <Image
            fluid={data.cup.childImageSharp.fluid}
            draggable={false}
            alt="Kitwind Blog"
            className="w-12 mr-4 sm:w-32 sm:mr-0"
          />
          <h1 className="inline-block mt-1 text-3xl font-extrabold leading-none sm:hidden">
            Latest Posts
          </h1>
        </div>
        <p className="block max-w-md mt-1 text-gray-700 sm:hidden">
          Here we share tips & tricks on how to build beautiful and outstanding
          stuff with Tailwind.
        </p>
        <div className="hidden mt-1 sm:block">
          <h1 className="inline-block mb-2 text-5xl font-extrabold leading-none">
            Latest Posts
          </h1>
          <p className="max-w-md -mt-1 text-lg text-gray-700">
            Here we share tips & tricks on how to build beautiful and
            outstanding stuff with Tailwind.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 md:gap-10 md:grid-cols-2 xl:grid-cols-2">
        {posts.map(({ node: post }, key) => (
          <PostItem post={post} key={key} />
        ))}
      </div>
    </LayoutApp>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogPageQuery {
    cup: file(absolutePath: { regex: "/cup.png/" }) {
      childImageSharp {
        fluid(maxWidth: 512, quality: 70) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allMdx(
      limit: 100
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
